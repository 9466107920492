import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { NhostApolloProvider } from '@nhost/react-apollo';
import { NhostClient, NhostProvider } from '@nhost/react';
import {
  split,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import axios from 'axios';

const localGraphql = '127.0.0.1:8787/graphql';
const nhostGraphql =
  'sxlkgonvrfwktylbjxqh.hasura.eu-central-1.nhost.run/v1/graphql';
const cloudflareGraphql =
  'graphql-makersunit-edge-proxy.jtiagodev.workers.dev/gql';

const wsLink = new WebSocketLink(
  new SubscriptionClient('wss://' + nhostGraphql, {
    reconnect: true,
  }),
);

const httpLink = new HttpLink({
  uri: 'https://' + cloudflareGraphql,
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const nhost = new NhostClient({
  region: 'eu-central-1',
  subdomain: 'sxlkgonvrfwktylbjxqh',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <NhostProvider nhost={nhost}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </NhostProvider>
  </React.Fragment>,
);
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
