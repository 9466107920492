import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

const MakersUnitGoogleEmbedMap =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3080.7313543889427!2d-8.613819022599046!3d39.45280357161092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd188fe32d28c9ed%3A0x2117e91ce2a0cb54!2sMakers%20Unit%20(FabLab%20Torres%20Novas)!5e0!3m2!1sen!2spt!4v1693762967082!5m2!1sen!2spt';

class GoogleMap extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60" fluid>
          <Row>
            <Col xs="12" className="p-0">
              <div className="map">
                <iframe
                  title="uniqueTitle"
                  src={MakersUnitGoogleEmbedMap}
                  style={{ border: '0', width: '100vw !important' }}
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default GoogleMap;
