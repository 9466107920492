import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Query } from "./Query";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { GET_MAKERSUNIT_TEAM } from "./Query/queries";

const TeamMembers = () => {
  
  return (
    <Query query={GET_MAKERSUNIT_TEAM}>
        {(data) => (
   
      <Container className=" mt-100 mt-60">
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className="section-title mb-4 pb-2 text-center">
            <h4 className="title">Equipa</h4>
              {/* <h4 className="title mb-4">Equipa</h4>
              <p className="text-muted mx-auto para-desc mb-0">
                Start working with{" "}
                <span className="text-primary fw-bold">
                  Landrick
                </span>{" "}
                that can provide everything you need to generate awareness,
                drive traffic, connect.
              </p> */}
            </div>
          </Col>
        </Row>
        <Row>
          {data.MakersUnit_team.filter(members => members.highlighted === true).map((members, key) => (
            <Col lg={3} md={6} className="mt-4 pt-2" key={key}>
              <Card className="team text-center border-0">
                <div className="position-relative" style={{
                    filter: "grayscale(100%)"
                }}>
                  <img src={members.picture} 
                  //className="img-fluid"
                   style={{ 
                    objectFit: "fill", 
                }} width="264px" height="264px" alt="" />
                  <ul className="list-unstyled mb-0 team-icon">
                    {/* <li className="list-inline-item ms-1">
                      <Link to="#" className="btn btn-primary btn-pills btn-sm btn-icon">
                        <i>
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </i>
                      </Link>
                    </li> */}
                    {/* <li className="list-inline-item ms-1">
                      <Link to="#" className="btn btn-primary btn-pills btn-sm btn-icon">
                        <i>
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </i>
                      </Link>
                    </li> */}
                    {/* <li className="list-inline-item ms-1">
                      <Link to="#" className="btn btn-primary btn-pills btn-sm btn-icon">
                        <i>
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </i>
                      </Link>
                    </li> */}
                     {members.email && (<li className="list-inline-item ms-1">
                      <a href={`mailto:${members.email}`} target="_blank" rel="noreferrer" className="btn btn-primary btn-pills btn-sm btn-icon">
                        <i>
                          <FeatherIcon
                            icon="mail"
                            className="fea icon-sm fea-social"
                          />
                        </i>
                      </a>
                    </li>)}
                    {members.website && (<li className="list-inline-item ms-1">
                      <a href={members.website} target="_blank" rel="noreferrer" className="btn btn-primary btn-pills btn-sm btn-icon">
                        <i>
                          <FeatherIcon
                            icon="file"
                            className="fea icon-sm fea-social"
                          />
                        </i>
                      </a>
                    </li>)}
                    {members.linkedin && (<li className="list-inline-item ms-1">
                      <a href={members.linkedin} target="_blank" rel="noreferrer" className="btn btn-primary btn-pills btn-sm btn-icon">
                        <i>
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </i>
                      </a>
                    </li>)}
                  </ul>
                </div>
                <div className="card-body py-3 px-0 content">
                  <h5 className="mb-0">
                    <Link to="#" className="name text-dark">
                      {members.name}
                    </Link>
                  </h5>
                  <small className="designation text-muted">
                    {members.jobtitle}
                  </small>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
        )}
    </Query>
    
  );
};
export default TeamMembers;

