import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Form, Row } from 'reactstrap';

import Creativity from './headline.png';

export default class Cabecalho extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="title-heading mt-4">
                  <h1 className="display-4 fw-bold mb-3">
                    Centro Tecnológico
                    <br /> em Torres Novas
                  </h1>
                  <p className="para-desc text-muted">
                    Com uma equipa especializada em projetos de inovação e
                    transformação digital, a Makers Unit tem soluções para
                    rentabilizar o seu negócio ou desenvolver o seu novo
                    projeto.
                  </p>
                </div>

                <div className="mt-3">
                  <a
                    href="https://www.makersunit.io/calendario/agendar-reuniao"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary me-2 mt-2"
                  >
                    <i className="mdi mdi-calendar"></i>
                    {' Agendar Reunião'}
                  </a>{' '}
                  <a
                    href="https://api.whatsapp.com/send?phone=+351919617171&text=Ol%C3%A1+Makers+Unit.+Preciso+de+ajuda"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-soft-primary mt-2"
                  >
                    <i className="mdi mdi-whatsapp"></i>

                    {' Enviar Mensagem'}
                  </a>
                </div>

                {/* <div className="subcribe-form mt-4 pt-2">
                  <Form>
                    <input type="email" id="email" name="email" className="border shadow rounded-pill"
                      required placeholder="Endereço de Email" />
                    <button type="submit" className="btn btn-pills btn-primary">Conheça as nossas soluções</button>
                  </Form>
                </div> */}

                {/* <div className="form-check mt-3">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label" htmlFor="flexCheckDefault">Eu aceito os {" "}
                  <Link to="#" className="text-primary">termos e condições</Link></label>
                </div> */}
              </Col>

              <Col md={6}>
                <img src={Creativity} alt="" />
              </Col>
            </Row>
          </Container>
        </section>

        <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
