import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import {
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import * as R from 'ramda';

const sliderSettings = {
  autoplay: true,
  infinite: true,
  autoplaySpeed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  draggable: true,
  pauseOnHover: true,
};

const ImagesSlider = ({ images }) => {
  {
    /* main slider */
  }
  <Slider
    {...sliderSettings}
    //asNavFor={this.state.nav2}
    //ref={(slider) => (this.slider1 = slider)}
  >
    {images.map((image, key) => (
      <div key={`image-${key}`}>
        <img src={image} className="img-fluid rounded" alt="" />
      </div>
    ))}
  </Slider>;
};
//Import Icons
import FeatherIcon from 'feather-icons-react';

import { useQuery } from '@apollo/client';
import { GET_MAKERSUNIT_FABLAB_SERVICES } from './Query/queries';

const useServicosFablabData = () => {
  let { loading, error, data } = useQuery(GET_MAKERSUNIT_FABLAB_SERVICES);

  return { data, loading, error };
};

const Equipamentos = () => {
  const { loading, error, data } = useServicosFablabData();
  const [activeTabIndex, setActiveTabIndex] = useState(null);

  useEffect(() => {
    if (data && data.MakersUnit_fablab_services) {
      setActiveTabIndex(data.MakersUnit_fablab_services[0].id);
    }
  }, [data]);
  const toggleTab = (index) => {
    if (activeTabIndex !== index) setActiveTabIndex(index);
  };

  if (loading === true) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  return (
    <React.Fragment>
      <Container className="mt-100 mt-60">
        <Row className="align-items-end mb-4 pb-2">
          <Col md={12}>
            <div className="section-title text-center text-md-start">
              {/* <h6 className="text-primary">Services</h6> */}
              <h4 className="title mb-4">Serviços</h4>
              <p className="text-muted mb-0 para-desc">
                A nossa FabLab permite explorar e concretizar as suas ideias de
                produtos de forma eficiente e económica.
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4} className="mt-4 pt-2">
            <ul
              className="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar"
              id="pills-tab"
              role="tablist"
            >
              {data.MakersUnit_fablab_services.map((servico, index) => {
                if (servico.display === true) {
                  return (
                    <Equipamento
                      key={`tabpane-${index}`}
                      index={index}
                      equipamento={servico}
                      activeTabIndex={activeTabIndex}
                      toggleTab={toggleTab}
                    />
                  );
                }
              })}
            </ul>
          </Col>

          <Col md={8} xs={12} className="mt-4 pt-2">
            <TabContent id="servicos-pills-tabContent" activeTab={activeTabIndex}>
              {data.MakersUnit_fablab_services.map((servico, index) => {
                if (servico.display === true) {
                  return (
                    <TabPane
                      key={`servico-${index}`}
                      className="fade show bg-white  p-4 rounded shadow"
                      tabId={servico.id}
                    >
                      <>
                      {servico.image && servico.image.includes(';') && (
                        <ImagesSlider images={servico.image.split(';')} />
                      )}
                      {servico.image && (
                        <img
                          style={{ maxHeight: '500px' }}
                          src={`https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/${servico.image}`}
                          className="img-fluid rounded" // shadow
                          alt=""
                        />
                      )}
                      <div className="mt-4">
                        <h5>{servico.name}</h5>
                        {servico.description && (
                          <p className="text-muted my-3">
                            {servico.description}
                          </p>
                        )}

                        {servico.status && (
                          <p className="text-muted my-3">{servico.status}</p>
                        )}

                        <a
                          href="https://www.makersunit.io/calendario/agendar-reuniao"
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary"
                        >
                          {`Fale connosco`}
                          <i>
                            <FeatherIcon
                              icon="arrow-right"
                              className="fea icon-sm"
                            />{' '}
                          </i>
                        </a>
                    
                      </div>
                      </>
                   </TabPane>
                  );
                }
              })}
            </TabContent>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const Equipamento = ({ equipamento, index, activeTabIndex, toggleTab }) => {
  return (
    <NavItem>
      <NavLink
        to="#"
        className={classnames(
          { active: activeTabIndex === equipamento.id },
          'rounded',
        )}
        onClick={() => {
          toggleTab(equipamento.id);
        }}
      >
        <div className="text-start py-1 px-2">
          <h6 className="mb-0">{equipamento.name}</h6>
        </div>
      </NavLink>
    </NavItem>
  );
};

export default Equipamentos;
