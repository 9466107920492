import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row, Alert } from 'reactstrap';
import ModalForm from './ModalForm';
import FAQ from './FAQ';

const CursoFullStack = () => {
  const [modal, setModal] = useState(false);
  const [succeMsg, setsucceMsg] = useState(false);

  const toggleModal = () => {
    setModal((modal) => !modal);
  };

  return (
    <>
      <section
        className="section"
        style={{
          background: `url(https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/b1372bcb-ea38-4b8f-86d1-75ca67582acd) center center`,
        }}
      >
        <Container
          style={{ border: '1px solid #202942' }}
          className="bg-white rounded py-md-5 py-3"
        >
          <Row>
            <Col
            // lg={{ size: 6, offset: 6 }}
            // md={{ size: 7, offset: 5 }}
            // xs={12}
            >
              <Card className="border-0">
                <CardBody

                //className="p-md-5 p-4 bg-white rounded"
                >
                  <div className="section-title">
                    <h4 className="title mb-4">
                      Curso Desenvolvedor Full-Stack
                    </h4>
                    <p className="text-muted para-desc mb-0">
                      14 semanas intensivas de aprendizagem acelerada para te
                      tornares um Full Stack Developer certificado.
                      <br />
                      Seguida de 1 mês de estágio em empresas parceiras ou
                      projetos internos.
                      <br />
                      Ideal para quem quer mudar de carreira e{' '}
                      <span style={{ color: '#2F55D4', fontWeight: 'bold' }}>
                        entrar no mercado de trabalho
                      </span>{' '}
                      como programador.
                      <br />
                      <br />
                      Temos soluções de alojamento e financiamento. Fale
                      connosco.
                    </p>
                  </div>

                  <Row>
                    <Col md={6} xs={12} className="mt-4">
                      <div className="d-flex align-items-center">
                        <div className="icon text-center rounded-circle h4 text-primary me-2 mb-0">
                          <i className="mdi mdi-map-marker"></i>
                        </div>
                        <div className="flex-1">
                          <h6 className="title text-dark mb-0">
                            Localização: Torres Novas
                          </h6>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} xs={12} className="mt-4">
                      <div className="d-flex align-items-center">
                        <div className="icon text-center rounded-circle h4 text-primary me-2 mb-0">
                          <i className="uil uil-clock"></i>
                        </div>
                        <div className="flex-1">
                          <h6 className="title text-dark mb-0">
                            Duração: 14 semanas
                          </h6>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} xs={12} className="mt-4">
                      <div className="d-flex align-items-center">
                        <div className="icon text-center rounded-circle h4 text-primary me-2 mb-0">
                          <i className="mdi mdi-information"></i>
                        </div>
                        <div className="flex-1">
                          <h6 className="title text-dark mb-0">
                            Regime: Full-Time
                          </h6>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} xs={12} className="mt-4">
                      <div className="d-flex align-items-center">
                        <div className="icon text-center rounded-circle h4 text-primary me-2 mb-0">
                          <i className="mdi mdi-seat"></i>
                        </div>
                        <div className="flex-1">
                          <h6 className="title text-dark mb-0">
                            Número de Lugares: 20
                          </h6>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} xs={12} className="mt-4">
                      <div className="d-flex align-items-center">
                        <div className="icon text-center rounded-circle h4 text-primary me-2 mb-0">
                          <i className="mdi mdi-currency-usd"></i>
                        </div>
                        <div className="flex-1">
                          <h6 className="title text-dark mb-0">
                            Preço: <s>4.000€</s> 3.500€ <br />
                            <span style={{ fontSize: '12px' }}>
                              (IVA incluído)
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} xs={12} className="mt-4">
                      <div className="d-flex align-items-center">
                        <div className="icon text-center rounded-circle h4 text-primary me-2 mb-0">
                          <i className="mdi mdi-certificate"></i>
                        </div>
                        <div className="flex-1">
                          <h6 className="title mb-0">
                            <span
                              style={{ color: '#202942', fontWeight: 'bold' }}
                            >
                              Certificação Incluída
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} xs={12} className="mt-4">
                      <div className="d-flex align-items-center">
                        <div className="icon text-center rounded-circle h4 text-primary me-2 mb-0">
                          <i className="mdi mdi-wallet"></i>
                        </div>
                        <div className="flex-1">
                          <h5 className="title text-dark mb-0">
                            <b>
                              {' '}
                              <span
                                style={{ color: '#2F55D4', fontWeight: 'bold' }}
                              >
                                Salário bruto médio inicial: 2.066€
                              </span>
                            </b>
                          </h5>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {succeMsg === true ? (
                    <Alert
                      style={{ marginTop: '20px' }}
                      color="info"
                      isOpen={succeMsg}
                      toggle={() => {
                        setsucceMsg(false);
                      }}
                    >
                      Mensagem enviada com successo! Entraremos em contacto
                    </Alert>
                  ) : (
                    <div className="mt-4">
                      <button onClick={toggleModal} className="btn btn-primary">
                        {/* <a
                      href="https://ekjq6cxpza6.typeform.com/to/QmyMiNDJ"
                      rel="noreferrer"
                      target="_blank"
                      className="btn btn-primary"
                    > */}
                        Inscrever-me{' '}
                        <i className="uil uil-angle-right-b align-middle"></i>
                        {/* </a> */}
                      </button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col
            // lg={{ size: 6, offset: 6 }}
            // md={{ size: 7, offset: 5 }}
            // xs={12}
            >
              {/* <Card className="border-0">
                <CardBody
                  style={{ border: '1px solid #202942' }}
                  className="p-md-5 p-4 bg-white rounded"
                >
                    <div className="section-title">
                    <h4 className="title mb-4">
                      Questões Frequentes
                    </h4>
                    </div> */}
              <FAQ />
              {/* </CardBody>
                  </Card>
                  */}
            </Col>
          </Row>
        </Container>
      </section>

      <ModalForm modal={modal} toggle={toggleModal} setsucceMsg={setsucceMsg} />
    </>
  );
};
export default CursoFullStack;
