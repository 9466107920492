import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Modal Video
import ModalVideo from 'react-modal-video';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';

const features = [
  'Uso mínimo de materiais',
  'Nenhum desperdício devido a estênceis ou restos de papel alumínio',
  'Sem uso de solventes ou diluentes nas paredes',
  'Inodoro e inofensivo',
  'Certificado',
];
class SmallFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (

              <React.Fragment>

        <Container 
        className="mt-100 mt-60"
        >
          <Row className="align-items-center">
            <Col lg={5} md={{ size: 6, order: 2 }} xs={{ size: 12, order: 1 }}>
              <img
                src="https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/f2721d9f-3d94-4d6a-9de1-1423cebc2566"
                className="img-fluid rounded"
                alt="Wall Printer"
              />
            </Col>
            <Col
              lg={7}
              md={6}
              className="col-12 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
            >
              <div className="section-title me-lg-4">
                {/* <p className="text-primary h2 mb-3"><i className="uil uil-layer-group"></i></p> */}
                <h4 className="title mb-3">Impressora Vertical UV</h4>
                {/* <br /> <span className="text-primary">Social Feeds</span> */}
                <p className="text-muted">
                  Qualquer superfície vertical. <br />
                  Qualquer imagem.
                  {/* <br/>Transforme paredes simples em algo surpreendente. */}
                </p>
                <ul className="list-unstyled text-muted">
                  {features.map((feature, key) => (
                    <li key={key} className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      {feature}
                    </li>
                  ))}
                </ul>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                  }}
                >
                  <div style={{ display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    marginRight: '20px' }} className="mt-2 mb-2">
                    <Link
                      to="#!"
                      className="btn btn-icon btn-pills btn-primary lightbox"
                      onClick={this.openModal}
                    >
                      <FeatherIcon icon="video" className="icons" />
                    </Link>
                    <span className="fw-bold text-uppercase small align-middle ms-1">
                      Ver Demonstração
                    </span>
                  </div>
                  <a
                    href="https://www.makersunit.io/calendario/agendar-reuniao"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary me-2 mt-2"
                  >
                    {' Pedir Orçamento'}
                  </a>{' '}
                </div>
              </div>
            </Col>
          </Row>
          <ModalVideo
            isOpen={this.state.isOpen}
            videoId="OdTsQJoOYFM"
            onClose={() => this.setState({ isOpen: false })}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default SmallFeatures;
