import React, { Component } from 'react';

import { Container } from 'reactstrap';
import SectionTitle from '../../../../components/Shared/SectionTitle';
import BootCamps from '../../components/Bootcamps';
import CursoFullStack from '../../components/CursoFullStack';
import FormacaoEmpresas from '../../components/FormacaoEmpresas';
export default class index extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.body.classList = '';
    // document.querySelector('.shoppingbtn').classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <section style={{ marginTop: '120px' }} className="section">
          <Container className="mt-100 mt-60">
            <SectionTitle
              title="Bootcamps Tecnológicos para Jovens"
              desc="Aliamos aprendizagem a atividades lúdicas"
            />
            <BootCamps cursoTipo="Bootcamp" />
          </Container>
        </section>

        <CursoFullStack />

      <section className="section">
          <Container className="mt-100 mt-60">
          <SectionTitle
            title="Formação em Empresas"
            desc="É cada vez mais importante ter competências digitais e de pensamento computacional em qualquer profissão. Desenvolvemos programas de formação em empresas com o objetivo de catalizar a revolução digital e o pensamento intra-empreendedor dentro das empresas. Os cursos têm duração de 6 a 16 horas."
          />

            <BootCamps cursoTipo="Empresas" />
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
