import { useQuery } from '@apollo/client';
import React, { useEffect, useState, Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { GET_MAKERSUNIT_CURSOS } from '../components/Query/queries';
import ModalForm from '../components/ModalFormFormacoes';

const useCourseData = (cursoTipo) => {
  const { data, loading, error } = useQuery(GET_MAKERSUNIT_CURSOS);
  const [bootcamps, setBootcamps] = useState([]);

  useEffect(() => {
    if (data && data.MakersUnit_cursos) {
      setBootcamps(
        data.MakersUnit_cursos.filter((curso) => curso.tipo === cursoTipo),
      );
    }
  }, [data]);

  return { data: bootcamps, loading, error };
};

const Bootcamps = ({ cursoTipo }) => {
  const { data, loading, error } = useCourseData(cursoTipo);
  const MAX_DESCRIPTION = 200;
  const [modal, setModal] = useState(false);
  const [succeMsg, setsucceMsg] = useState(false);
  const [formacao, setFormacao] = useState('');

  const toggleModal = () => {
    setModal((modal) => !modal);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: ${error.toString()}</p>;

  return (
    <>
      <Row>
        {data.map(
          (
            {
              id,
              imagem,
              titulo,
              idades,
              descricao,
              duracao,
              custo,
              formulario,
              tipo,
            },
            key,
          ) => (
            <Col lg="4" md="6" xs="12" key={key} className="mt-4 pt-2">
              <Card style={{ minHeight: tipo === "Empresas" ? "560px" : '' }} className="blog blog-primary overflow-hidden rounded shadow border-0">
                <div  className="position-relative d-block overflow-hidden">
                  {imagem && (
                    <img
                      src={`https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/${imagem}`}
                      className="img-fluid rounded-top mx-auto"
                      alt=""
                    />
                  )}
                  <div className="overlay-work bg-dark"></div>
                  {/* <Link to={`/curso/${id}`} className="h6 preview bottom-0 end-0 me-4 mb-3">
                  <span style={{ color: "#005495" }}>Mais Detalhes{' '}</span>
                  <i style={{ color: "#005495" }} className="uil uil-angle-right-b align-middle"></i>
                </Link> */}
                </div>
                <CardBody style={{ minHeight: '300px' }} className="content">
                  <h5>{titulo}</h5>
                  {tipo === 'Bootcamp' ? (
                    <p className="text-muted">
                      {descricao.slice(0, MAX_DESCRIPTION) +
                        (descricao.length > MAX_DESCRIPTION ? '...' : '')}
                    </p>
                  ) : (
                    <p className="text-muted">{descricao}</p>
                  )}

                  <div className="fees d-flex justify-content-between">
                    <ul className="list-unstyled mb-0 numbers">
                      {idades && (
                        <li>
                          <i className="uil uil-graduation-cap text-muted"></i>{' '}
                          {idades}
                        </li>
                      )}
                      {duracao && (
                        <li>
                          <i className="uil uil-notebooks text-muted"></i>{' '}
                          {duracao}
                        </li>
                      )}
                    </ul>
                    {custo && (
                      <h4>
                        <span className="h6">$</span>
                        {custo}
                      </h4>
                    )}
                    {/* <button
                      onClick={() => {
                        setFormacao(titulo);
                        toggleModal();
                      }}
                      className="btn btn-primary"
                    >
                      Efetuar Pedido{' '}
                      <i className="uil uil-angle-right-b align-middle"></i>
                    </button> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          ),
        )}
      </Row>
      {/* <ModalForm
        modal={modal}
        toggle={toggleModal}
        setsucceMsg={setsucceMsg}
        formacao={formacao}
      /> */}
    </>
  );
};

export default Bootcamps;
