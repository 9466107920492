import React, { useState } from 'react';
import {
  Alert,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//editor
import { useMutation } from '@apollo/client';
import { INSERT_MAKERSUNIT_MESSAGE } from './Query/queries';

// Formik Validation
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ModalForm = ({ setsucceMsg, modal, toggle }) => {
  const [sendMessage, { data, loading, error }] = useMutation(
    INSERT_MAKERSUNIT_MESSAGE,
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Preenchimento obrigatório'),
      email: Yup.string().required('Preenchimento obrigatório'),
      message: Yup.string().required('Preenchimento obrigatório'),
    }),
    onSubmit: (values) => {
      setsucceMsg(true);
      const { name, email, message } = values;
      sendMessage({
        variables: {
          name,
          email,
          message,
          additional_info: { source: 'Candidatura Full-Stack Developer' },
        },
      });
      toggle();
    },
  });

  return (
    <Modal
      tabIndex="-1"
      centered
      size="lg"
      isOpen={modal}
      toggle={toggle}
      contentClassName="rounded shadow border-0"
    >
      <Form
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        name="fullstack-form"
        id="fullstack-form"
      >
        <ModalHeader toggle={toggle}>
          Curso Intensivo Desenvolvedor Full-Stack
        </ModalHeader>
        <ModalBody className="p-4">
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <FormGroup className="mb-3">
                  <Label className="form-label">
                    Nome <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <i>
                      {' '}
                      <FeatherIcon icon="user" className="fea icon-sm icons" />
                    </i>
                    <Input
                      name="name"
                      id="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ''}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                      className="form-control ps-5"
                      placeholder="O teu nome"
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <FormGroup className="mb-3">
                  <Label className="form-label">
                    E-mail <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <i>
                      <FeatherIcon icon="book" className="fea icon-sm icons" />
                    </i>
                    <Input
                      name="email"
                      id="email"
                      className="form-control ps-5"
                      placeholder="O teu email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ''}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </div>
            </Col>
            <Col className="col-12">
              <div id="editor">
                <FormGroup className="mb-3">
                  <Label className="form-label">
                    Motivação <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="message"
                    id="message"
                    type="textarea"
                    rows="4"
                    className="form-control"
                    placeholder="Fala-nos um pouco sobre a tua motivação e disponibilidade para começar o curso"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.message || ''}
                    invalid={
                      validation.touched.message && validation.errors.message
                        ? true
                        : false
                    }
                  />
                  {validation.touched.message && validation.errors.message ? (
                    <FormFeedback type="invalid">
                      {validation.errors.message}
                    </FormFeedback>
                  ) : null}
                  {/* <CKEditor
              editor={ClassicEditor}
              data="<p>Hello,<br /><br /> 
            Great doctor if you need your family member to get effective immediate assistance, 
            emergency treatment or a simple consultation. <br /><br />Thank you</p>"
                onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.

                }}
                onChange={(event, editor) => {
                    editor.getData();
                }}
            /> */}
                </FormGroup>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <div className="border-top p-4">
          <input
            type="submit"
            id="submit"
            name="send"
            className="submitBnt btn btn-primary"
            value="Submeter Candidatura"
          />
        </div>
      </Form>
    </Modal>
  );
};

export default ModalForm;
