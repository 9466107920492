import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, Container, Table } from 'reactstrap';
import { GET_MAKERSUNIT_PRECARIO } from '../components/Query/queries';

const Precario = () => {
  const { data, loading, error } = useQuery(GET_MAKERSUNIT_PRECARIO);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  return (
    <Container className="mt-100 mt-60">
      <Col lg={12} md={12}>
        <div className="section-title sticky-bar position-sticky">
          {/* <span className="badge rounded-pill bg-soft-primary">
                Preçário
              </span> */}
          <h4 className="title mt-3 mb-4">Preçário Materiais</h4>
        </div>
      </Col>
      <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
        <Table className="table-hover mb-0 table-center">
          {data.MakersUnit_precario.map((precario, index) => {
            return (
              <React.Fragment key={index}>
                <thead>
                  <tr>
                    <th scope="col" className="border-bottom">
                      {precario.categoria}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(precario.precos).map(([key, value]) => (
                    <tr key={`${index}-${key}`}>
                      <td>{key}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </tbody>
              </React.Fragment>
            );
          })}
        </Table>
      </Col>
    </Container>
  );
};

export default Precario;
