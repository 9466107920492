import { gql, useQuery } from '@apollo/client';

export const SUBSCRIBE_MAKERSUNIT_TORNEIOS = gql`
  subscription MUTorneios {
    events {
      maxParticipants
      minParticipants
      startsOn
      title
      type
      id
      gametitle
      endsOn
      matches {
        id
        name
        nextMatchId
        startTime
        state
        tournamentRoundText
        participants: match_participants {
          id: partcipant
          isWinner
          name: attendee {
            name
          }
          resultText
          status
        }
      }
      game: gametitleByGametitle {
        title
      }
    }
  }
`;

export const GET_MAKERSUNIT_TORNEIOS = gql`
  query MUTorneios {
    events {
      maxParticipants
      minParticipants
      startsOn
      title
      type
      id
      gametitle
      endsOn
      matches {
        id
        name
        nextMatchId
        startTime
        state
        tournamentRoundText
        participants: match_participants {
          id: partcipant
          isWinner
          name
          resultText
          status
        }
      }
    }
  }
`;

export const GET_MAKERSUNIT_TEAM = gql`
  query MUTeam {
    MakersUnit_team {
      email
      highlighted
      id
      jobtitle
      linkedin
      name
      picture
      website
    }
  }
`;

export const GET_MAKERSUNIT_NEWS = gql`
  query MUNews {
    MakersUnit_news {
      id
      title
      date
      url
      picture
      preview
    }
  }
`;

export const GET_MAKERSUNIT_EVENT_CATEGORIES = gql`
  query MUEventCategories {
    MakersUnit_event_categories {
      id
      title
    }
  }
`;

export const GET_MAKERSUNIT_EVENTS = gql`
  query MUEvents {
    MakersUnit_events {
      category
      title
      address
      url
      buttonText
      address
      isVirtual
      startsOn
      endsOn
      speakers
      preco
      oradoresButton
    }
  }
`;

export const GET_MAKERSUNIT_FABLAB_EQUIPMENT = gql`
  query MUFablabEquipment {
    MakersUnit_fablab_equipment(order_by: { name: asc }) {
      id
      description
      materials
      specs
      name
      category
      book_url
      price
      status
      display
      image
    }
  }
`;

export const GET_MAKERSUNIT_FABLAB_PORTFOLIO = gql`
  query MUFablabPortfolio {
    MakersUnit_fablab_portfolio {
      id
      image
      url
      materials
      title
      description
      categoria
    }
  }
`;

export const INSERT_MAKERSUNIT_MESSAGE = gql`
  mutation MUInsertMessage(
    $email: String!
    $message: String!
    $name: String! # $subject: String!
    $additional_info: json
    $subject: String
  ) {
    insert_MakersUnit_messages(
      objects: {
        email: $email
        message: $message
        name: $name
        additional_info: $additional_info
        subject: $subject
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_MAKERSUNIT_PRECARIO = gql`
  query MUPrecario {
    MakersUnit_precario {
      id
      categoria
      precos
    }
  }
`;

export const SUBSCRIBE_MAKERSUNIT_PRECARIO = gql`
  subscription MUPrecario {
    MakersUnit_precario {
      id
      categoria
      precos
    }
  }
`;

export const GET_MAKERSUNIT_MENU_CATEGORIES = gql`
  query MUMenuCategories {
    MakersUnit_menu_categories(order_by: { title: asc }) {
      id
      title
      includeImage
    }
  }
`;

export const GET_MAKERSUNIT_MENU = gql`
  query MUMenuCategories {
    MakersUnit_menu(order_by: { title: asc }) {
      id
      description
      title
      price
      image
      category
    }
  }
`;

export const SUBSCRIBE_MAKERSUNIT_MENU_CATEGORIES = gql`
  subscription MUMenuCategories {
    MakersUnit_menu_categories(order_by: { title: asc }) {
      id
      title
      includeImage
    }
  }
`;

export const SUBSCRIBE_MAKERSUNIT_MENU = gql`
  subscription MUMenuCategories {
    MakersUnit_menu(order_by: { title: asc }) {
      id
      description
      title
      price
      image
      category
    }
  }
`;

export const GET_MAKERSUNIT_CURSOS = gql`
  query MUCursos {
    MakersUnit_cursos {
      id
      imagem
      titulo
      idades
      descricao
      duracao
      custo
      formulario
      tipo
    }
  }
`;

export const GET_MAKERSUNIT_FABLAB_SERVICES = gql`
  query MUFablabServices {
    MakersUnit_fablab_services(order_by: { name: asc }) {
      id
      description
      name
      price
      status
      display
      image
      business_cases
    }
  }
`;
