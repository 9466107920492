import React from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';

export const Query = ({ query, children }) => {
  const { loading, data, error } = useQuery(query);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error in the query {error.message}</div>;
  }

  return children(data);
};

export const Subscription = ({ query, children }) => {
  const { loading, data, error } = useSubscription(query);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error in the subscription {error.message}</div>;
  }

  return children(data);
};
