// React Basic and Bootstrap
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Components
import SectionTitle from '../../../components/Shared/SectionTitle';

const WorkProcess = () => {
  const studioWorksData = [
    {
      imageUrl:
        'https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/105c8555-f6b8-4741-af19-8880c8f24dcb',
      title: 'Pool',
      subtitle: '6€ / hora',
      reservation: 'https://cal.com/makersunit/reservar-pool',
    },
    {
      imageUrl:
        'https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/6d52f1ce-ae88-4a4c-b046-e27088a614da',
      title: 'Matraquilhos',
      subtitle: '0.5€ / jogo',
    },
    // { imageUrl: img3, title: "Abstract images", subtitle: "Abstract" },
    // { imageUrl: img4, title: "Yellow bg with Books", subtitle: "Books" },
    // { imageUrl: img5, title: "Company V-card", subtitle: "V-card" },
    // {
    //   imageUrl: img6,
    //   title: "Mockup box with paints",
    //   subtitle: "Photography",
    // },
  ];

  const [modal, setModal] = useState(false);
  function tog_modal() {
    setModal(!modal);
  }

  return (
    <React.Fragment>
      <Container className="mt-100 pt-50">
        {/* section title */}
        <SectionTitle
          title="Jogos e Lazer"
          //desc="that can provide everything you need to generate awareness, drive traffic, connect"
        />

        <Row>
          {studioWorksData.map((work, key) => (
            <Col md={6} key={key} className="col-12 mt-4 pt-2">
              <Card className="border-0 work-container work-primary work-classic">
                <CardBody className="p-0">
                  <img
                    src={work.imageUrl}
                    className="img-fluid rounded work-image"
                    alt="work"
                    style={{ maxHeight: 300 }}
                  />

                  <div className="content pt-3">
                    <h5 className="mb-0">{work.title}</h5>
                    <h6 className="text-muted tag mb-0">{work.subtitle}</h6>
                    {work.reservation && (
                      <a
                        href="https://www.makersunit.io/calendario/reservar-pool"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                      >
                        {` Reservar Mesa`}
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm"
                          />{' '}
                        </i>
                      </a>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default WorkProcess;
