// React Basic and Bootstrap
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';

//Import Components
import { useQuery } from '@apollo/client';
import SectionTitle from '../../../../components/Shared/SectionTitle';
import {
  GET_MAKERSUNIT_EVENTS,
  GET_MAKERSUNIT_EVENT_CATEGORIES,
} from '../Query/queries';
import EventList from './EventList';

function isDateBeforeToday(date) {
  return (
    new Date(new Date(date).toDateString()) >
    new Date(new Date().toDateString())
  );
}

const Events = () => {
  const {
    loading: loadingEventCategories,
    error: errorEventCategories,
    data: eventCategoriesData,
  } = useQuery(GET_MAKERSUNIT_EVENT_CATEGORIES);
  const {
    loading: loadingEvents,
    error: errorEvents,
    data: eventsData,
  } = useQuery(GET_MAKERSUNIT_EVENTS);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    if (eventsData && eventsData.MakersUnit_events) {
      setUpcomingEvents(
        eventsData.MakersUnit_events.filter((event) =>
          isDateBeforeToday(event.endsOn),
        ).sort(
          (a, b) =>
            new Date(a.startsOn).getTime() - new Date(b.startsOn).getTime(),
        ),
      );
    }
  }, [eventsData]);

  if (loadingEventCategories || loadingEvents) return <p>Loading...</p>;
  if (errorEventCategories)
    return <p>{`Error ${errorEventCategories.toString()}`}</p>;
  if (errorEvents) return <p>{`Error ${errorEvents.toString()}`}</p>;

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <SectionTitle title="Calendário" desc="" />
          <EventList
            categories={eventCategoriesData.MakersUnit_event_categories}
            events={upcomingEvents}
          />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Events;
