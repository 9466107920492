import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  FormFeedback,
} from 'reactstrap';
import { useMutation } from '@apollo/client';
import { INSERT_MAKERSUNIT_MESSAGE } from './Query/queries';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Components
import SectionTitle from '../../../components/Shared/SectionTitle';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

const contactos = [
  {
    titulo: 'Administração',
    email: 'admin@makersunit.com',
    tel: '+351919617171',
  },
  {
    titulo: 'Direção Comercial',
    email: 'sales@makersunit.com',
    tel: '+351912783838',
  },
  {
    titulo: 'Direção Técnica',
    email: 'technical@makersunit.com',
    tel: '+351964702904',
  },
  {
    titulo: 'Academia da Tecnologia',
    email: 'academy@makersunit.com',
    tel: '+351917204287',
  },
];

const Contacto = ({ titulo, tel, email }) => {
  return (
    <Card className="border-0 bg-transparent">
      <CardBody className="p-0">
        <div
          className="contact-detail d-flex align-items-center mt-3"
          //data-aos="fade-up"
          //data-aos-duration="1200"
        >
          <div className="icon">
            {/* <i>
          <FeatherIcon
            icon="mail"
            className="fea icon-m-md text-dark me-3"
          />
        </i> */}
          </div>
          <div
            className="content overflow-hidden d-flex"
            style={{
              flexDirection: 'column',
            }}
          >
            <h6 className="fw-bold mb-0">{titulo}</h6>
            <a href={`mailto:${email}`} className="text-primary">
              {email}
            </a>
            <a href={`tel:${tel}`} className="text-primary">
              {tel}
            </a>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
const ContactUs = () => {
  const [sendMessage, { data, loading, error }] = useMutation(
    INSERT_MAKERSUNIT_MESSAGE,
  );

  const [succeMsg, setsucceMsg] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Preenchimento obrigatório'),
      email: Yup.string().required('Preenchimento obrigatório'),
      message: Yup.string().required('Preenchimento obrigatório'),
    }),
    onSubmit: (values) => {
      setsucceMsg(true);
      const { name, email, message } = values;
      sendMessage({ variables: { name, email, message, additional_info: { source: "Contacto Geral" } } });
    },
  });
  return (
    <React.Fragment>
      <Container>
        {/* section title */}
        <SectionTitle
          title="Contacte-nos"
          //desc="that can provide everything you need to generate awareness, drive traffic, connect."
        />

        <Row className="align-items-center">
          <Col
            lg="5"
            md={{ size: 6, order: 1 }}
            xs={{ order: 2 }}
            className="mt-4 pt-2"
          >
            <Card
              className="rounded shadow border-0"
              //data-aos="fade-up"
              //data-aos-duration="1000"
            >
              <CardBody>
                <div className="custom-form bg-white">
                  <div id="message"></div>
                  <Alert
                    color="info"
                    isOpen={succeMsg}
                    toggle={() => {
                      setsucceMsg(false);
                    }}
                  >
                    Mensagem enviada com successo!
                  </Alert>
                  <Form
                    method="post"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    name="contact-form"
                    id="contact-form"
                  >
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Nome <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Seu Nome:"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ''}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      

                      <Col lg="6">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control ps-5"
                            placeholder="Seu Email:"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">
                            Mensagem <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="message"
                            id="message"
                            type="textarea"
                            className="form-control ps-5"
                            placeholder="Sua Mensagem:"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.message || ''}
                            invalid={
                              validation.touched.message &&
                              validation.errors.message
                                ? true
                                : false
                            }
                          />
                          {validation.touched.message &&
                          validation.errors.message ? (
                            <FormFeedback type="invalid">
                              {validation.errors.message}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12" className="text-center">
                        <div className="d-grid">
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary"
                            value="Enviar Mensagem"
                          />
                          
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col
            lg="7"
            md={{ size: 6, order: 2 }}
            xs={{ order: 1 }}
            className="mt-4 pt-2 order-1 order-md-2"
          >
            <div className="title-heading ms-lg-4">
              {/* <h4
                className="mb-4"
                //data-aos="fade-up"
                //data-aos-duration="1000"
              >
                Contact Details
              </h4>
              <p
                className="text-muted"
                //data-aos="fade-up"
                //data-aos-duration="1400"
              >
                Start working with{" "}
                <span className="text-primary fw-bold">
                  Landrick
                </span>{" "}
                that can provide everything you need to generate awareness,
                drive traffic, connect.
              </p> */}

              {contactos.map((contacto, key) => (
                <Contacto key={key} {...contacto} />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ContactUs;
