import React, { Component } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Container, Row, Col, UncontrolledCollapse } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const generateRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const questoesCursoFullStack = [
    {
      pergunta: 'A quem se destina este curso?',
      resposta:
        'Este curso destina-se a todas as pessoas com mais de 18 anos, que estejam desempregadas ou disponíveis para embarcar numa nova e promissora carreira como Junior FullStack Developers, demonstrando uma determinação inquebrantável e apaixonada.'
    },
    {
      pergunta: 'Qual a duração do curso?',
      resposta:
        'A duração deste programa é de 14 semanas, em regime de full-time intensivo e absolutamente imersivo. Esta abordagem é necessária devido à natureza da programação, que exige uma entrega total. Durante esse período, os participantes adquirem habilidades essenciais para ingressar no mercado de trabalho como programadores Full Stack.'
    },
    {
      pergunta: 'É preciso ter experiência prévia em programação?',
      resposta:
        'Não é necessário ter experiência prévia em programação. Este curso é amigável para iniciantes, e a nossa equipa está altamente preparada para iniciar os participantes nas artes da programação e do pensamento algorítmico. No entanto, é fundamental que os candidatos saibam ler, escrever e comunicar em inglês, pois esta é a linguagem fundamental no mundo da programação.'
    },
    {
      pergunta: 'Qual o grau académico mínimo necessário?',
      resposta:
        'Não é necessário ter um grau académico mínimo para se inscrever. Estamos à procura de mentes brilhantes, independentemente de qualquer título formal. O nosso foco principal é nas pessoas e na sua paixão pela programação.'
    },
    {
      pergunta: 'O que vou aprender?',
      resposta:
        'Este curso permitirá que desenvolva uma mentalidade de programação Full Stack, que lhe permitirá transitar livremente entre o desenvolvimento de back-end e front-end. Terá a oportunidade de experimentar as técnicas mais atuais de produção de software, num ambiente criativo e estimulante. Tornar-se-á fluente em boas práticas de programação e dominará as frameworks mais modernas disponíveis. No entanto, o principal foco será ensinar a aprender, uma das habilidades mais valiosas para um programador.'
    },
    {
      pergunta: 'Qual o horário das aulas?',
      resposta:
        'Oficialmente, as aulas ocorrem de segunda a sexta-feira, das 09h às 18h. No entanto, na prática, espera-se que dedique grande parte das suas noites e fins de semana ao curso, trabalhando em projetos individuais e de grupo. Durante 14 semanas, o curso se tornará uma parte significativa da sua vida.'
    },
    {
      pergunta: 'Existem condições de financiamento?',
      resposta:
        'O custo total do curso é de 3.500€. No entanto pode explorar opções de financiamento junto de entidades de crédito com as quais protocolamos ou através do programa de ISA (Income Share Agreement).'
    },
    {
      pergunta: 'Tenho emprego garantido após o curso?',
      resposta:
        'Oferecemos educação de alta qualidade em um campo onde a taxa de desemprego é praticamente nula. Apoiamos a sua inserção no mercado de trabalho e contamos com uma equipa dedicada para ajudá-lo a encontrar oportunidades de emprego.'
    },
    {
      pergunta: 'Como funciona o processo de seleção?',
      resposta:
        'Para se tornar aluno do nosso curso, deve completar com sucesso as duas fases do nosso processo de seleção. A primeira fase envolve desafios propostos pela nossa plataforma de candidatura online. A segunda fase consiste em participar de um longo e intenso dia de workshop, realizado pela nossa equipa. Este processo não tem custos associados e serve para avaliar a sua preparação para ingressar no nosso programa.'
    },
    {
      pergunta: 'Como funciona a certificação?',
      resposta:
        'Durante o curso, terá a oportunidade de obter pelo menos duas certificações. Ao concluir o programa, será certificado pela Makers Unit como um Full Stack Developer. Além disso, receberá um certificado de estágio junto de um dos nossos parceiros tecnológicos.'
    }
  ];

const Accordion = () => {
  return (
    <div className="accordion" id="buyingquestion">
      {questoesCursoFullStack.map((questao, index) => {
        const id = generateRandomString(6);
        return (
          <div key={`questao-${index}`} className="accordion-item rounded">
            <h2 className="accordion-header">
              <button
                id={id}
                className="accordion-button border-0 bg-light"
                type="button"
              >
                {questao.pergunta}
              </button>
            </h2>
            <UncontrolledCollapse
              //defaultOpen={index === 0}
              toggler={`#${id}`}
              className="border-0"
            >
              <div className="accordion-body text-muted">
                {questao.resposta}
              </div>
            </UncontrolledCollapse>
          </div>
        );
      })}
    </div>
  );
};
export default Accordion;
