import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { GET_MAKERSUNIT_FABLAB_PORTFOLIO } from './../components/Query/queries';
//Import Icons

//Fade in effect
import FadeIn from 'react-fade-in';

//Import Images

const useFilteredData = (displayCategory) => {
  const { data, loading, error } = useQuery(GET_MAKERSUNIT_FABLAB_PORTFOLIO);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState([]);
  const ALL_CATEGORIES = 'Todos';
  const getCategories = (data) => {
    const categories = data.map(({ categoria }) => categoria);
    return [ALL_CATEGORIES, ...new Set(categories)];
  };

  useEffect(() => {
    if (data && data.MakersUnit_fablab_portfolio) {
      setFilteredData(
        data.MakersUnit_fablab_portfolio.filter(
          ({ categoria }) =>
            displayCategory === categoria || displayCategory === ALL_CATEGORIES,
        ),
      );
      setCategories(getCategories(data.MakersUnit_fablab_portfolio));
    }
  }, [data, displayCategory]);

  return { data: filteredData, loading, error, categories };
};

const Portfolio = () => {
  const [displayCategory, setCategory] = useState('Todos');
  const { data, loading, error, categories } = useFilteredData(displayCategory);

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);

    return function cleanup() {
      window.removeEventListener('scroll', scrollNavigation, true);
    };
  }, []);

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    var topnav = document.getElementById('topnav');
    if (top > 80 && topnav) {
      topnav.classList.add('nav-sticky');
    } else if (topnav) {
      topnav.classList.remove('nav-sticky');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error {error.toString()}</p>;

  return (
    <React.Fragment>
      {/* breadcrumb */}
      <Container className="mt-100 mt-60">
        <Col lg={12} md={12}>
          <div className="section-title sticky-bar position-sticky">
            {/* <span className="badge rounded-pill bg-soft-primary">
              Portfolio
            </span> */}
            <h4 className="title mt-3 mb-4">Portfolio</h4>
            {/* <p className="text-muted para-desc mb-0">
              Start working with{" "}
              <span className="text-primary fw-bold">
                Landrick
              </span>{" "}
              that can provide everything you need to generate awareness,
              drive traffic, connect.
            </p> */}
          </div>
        </Col>
        <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
          <Row>
            <div className="col-12 filters-group-wrap">
              <div className="filters-group">
                <ul
                  className="container-filter list-inline mb-0 filter-options"
                  id="filter"
                >
                  {categories.map((category, key) => (
                    <li
                      key={key}
                      onClick={() => setCategory(category)}
                      className={
                        displayCategory === category
                          ? 'list-inline-item categories-name border text-dark rounded active'
                          : 'list-inline-item categories-name border text-dark rounded '
                      }
                    >
                      {category}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Row>

          <Row className="projects-wrapper">
            {data.length === 0 && (
              <Col xs={12} className="text-center">
                <span>Sem resultados a apresentar</span>
              </Col>
            )}
            {data.map(({ image, url, title, description }, key) => (
              <Col key={key} lg={3} xs={12} className="mt-4 pt-2 offices">
                <FadeIn delay={100}>
                  <Card className="border-0 work-container work-classic shadow overflow-hidden">
                    <a href={url} target="_blank" rel="noreferrer">
                      <CardBody className="p-0">
                        <img src={image} className="img-fluid work-image" />

                        <div className="content p-4">
                          <h5 className="mb-0">{title}</h5>
                          <h6 className="text-muted tag mb-0">{description}</h6>
                        </div>
                      </CardBody>
                    </a>
                  </Card>
                </FadeIn>
              </Col>
            ))}
          </Row>
        </Col>
      </Container>
    </React.Fragment>
  );
};

export default Portfolio;
