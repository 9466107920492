import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//import images
import online1 from '../../../assets/images/course/online/ab01.jpg';
import online2 from '../../../assets/images/course/online/ab02.jpg';
import online3 from '../../../assets/images/course/online/ab03.jpg';

const Aboutus = () => {
        return (
            <React.Fragment>
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6} md={6}>
                            <Row className="align-items-center">
                                <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                                    <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                        <CardBody className="p-0">
                                            <img style={{ filter: "grayscale(100%)" }} src="https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/5da76f76-3718-4379-952e-5e4881a4a692" className="img-fluid" alt="" />
                                            <div className="overlay-work bg-dark"></div>
                                            <div className="content">
                                                <Link to="#" className="title text-white d-block fw-bold">Academia</Link>
                                                <small className="text-light">Formação e Certificação</small>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={6} xs={6}>
                                    <Row>
                                        <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                                            <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                                <CardBody className="p-0">
                                                    <img style={{ filter: "grayscale(100%)" }} src="https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/b30ee546-5a52-41ad-8125-5e472997c4bf" className="img-fluid" alt="" />
                                                    <div className="overlay-work bg-dark"></div>
                                                    <div className="content">
                                                        <Link to="#" className="title text-white d-block fw-bold">FabLab</Link>
                                                        <small className="text-light">Laboratório de Prototipagem</small>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col lg={12} md={12} className="mt-4 pt-2">
                                            <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                                <CardBody className="p-0">
                                                    <img style={{ filter: "grayscale(100%)" }} src="https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/77f8620d-c322-4236-8d93-3517214fa66d" className="img-fluid" alt="" />
                                                    <div className="overlay-work bg-dark"></div>
                                                    <div className="content">
                                                        <Link to="#" className="title text-white d-block fw-bold">Lounge</Link>
                                                        <small className="text-light">Café e Entretenimento</small>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
                            <div className="ms-lg-4">
                                <div className="section-title">
                                    <span className="badge bg-soft-primary rounded-pill fw-bold">Sobre Nós</span>
                                    <h4 className="title mb-4 mt-3">Missão</h4>
                                    <p className="text-muted para-desc">Integrar, dignificar e aumentar o conhecimento tecnologico nas regiões do interior do país. Formar e certificar quadros especializados em novas tecnologicas, garantindo uma elevada empregabilidade através da realização de protocolos com entidades públicas e privadas.</p>
                                    {/* <p className="text-muted para-desc mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words.</p> */}
                                </div>

                                <div className="d-flex mt-4 pt-2">
                                    <i className="mdi mdi-school h5 text-primary"></i>
                                    <div className="flex-1 ms-2">
                                        <h5>Programas de Formação​</h5>
                                        <p className="mb-0 text-muted"><b>Centro de formação</b> com programas que vão desde a iniciação á informática até à certificação na especialização em tecnologias de informação.</p>
                                    </div>
                                </div>

                                <div className="d-flex mt-4 pt-2">
                                    <i className="mdi mdi-pencil-ruler h5 text-primary"></i>
                                    <div className="flex-1 ms-2">
                                        <h5>Laboratório de Prototipagem​</h5>
                                        <p className="mb-0 text-muted"><b>FabLab</b> que disponibiliza equipamento de prototipagem rápida e/ou digital a estudantes, investigadores, empreendores e inventores.</p>
                                    </div>
                                </div>

                                <div className="d-flex mt-4 pt-2">
                                    <i className="mdi mdi-code-brackets h5 text-primary"></i>
                                    <div className="flex-1 ms-2">
                                        <h5>Serviços de Apoio à Modernização​</h5>
                                        <p className="mb-0 text-muted"><b>Centro de competências</b> capacitado para o desenvolvimento de novas soluções tecnológicas, disponibilizando um gabinete de apoio no ambito do desenvolvimento regional.</p>
                                    </div>
                                </div>
                                
                                <div className="d-flex mt-4 pt-2">
                                    <i className="mdi mdi-account-group h5 text-primary"></i>
                                    <div className="flex-1 ms-2">
                                        <h5>Espaço Colaborativo​</h5>
                                        <p className="mb-0 text-muted">Fomento da partilha de conhecimento tecnológico e das suas mais valias junto da comunidade local. Promoção de feiras e eventos de cariz tecnológico.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );    
};
export default Aboutus;
