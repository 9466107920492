// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import classnames from 'classnames';

const getHorario = (event) => {
  const startHour = (new Date(event.startsOn)).getHours() //.substr(-2);
  const startMinutes = '0'+(new Date(event.startsOn)).getMinutes() //.substr(-2);
  const endHour = (new Date(event.endsOn)).getHours() //.substr(-2);
  const endMinutes = '0'+(new Date(event.endsOn)).getMinutes() //.substr(-2);
  return `${startHour}:${startMinutes}-${endHour}:${endMinutes}`;
};

export const startDateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
export const endDateOptions = { hour: 'numeric', minute: 'numeric' };

const EventListItem = ({ categories, events }) => {

  return (
    <Row>
      {events.map((event, key) => (
        <Col lg={6} key={`event-${key}`} className="mt-4 pt-2">
          <Card style={{ minHeight: '243px' }} className="event-schedule event-primary rounded border">
            <CardBody>
              <div className="d-flex">
                <ul className="date text-center me-3 mb-0 list-unstyled">
                  <li className="day fw-bold mb-2">
                    {new Date(event.startsOn).getDate()}
                  </li>
                  <li className="month fw-bold">
                    {new Date(event.startsOn)
                      .toLocaleString('pt-PT', { month: 'short' })
                      .toUpperCase()}
                  </li>
                </ul>
                <div className="flex-1 content">
                  <span>
                    {
                      categories.filter(
                        (category) => category.id === event.category,
                      )[0].title
                    }
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <h4>
                      {event.url ? (
                        <a
                          href={event.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-dark title"
                        >
                          {event.title}
                        </a>
                      ) : (
                        event.title
                      )}
                    </h4>
                  </div>

                  <p className="text-muted location-time">
                    <span className="text-dark h6">Morada:</span>{' '}
                    {event.address
                      ? event.address
                      : 'Largo do Comércio 3, Parceiros de Igreja'}{' '}
                    <span className="text-danger">
                      {event.isVirtual ? ' (Virtual)' : ''}
                    </span>{' '}
                    <br /> <span className="text-dark h6">Horário:</span>{' '}
                    {getHorario(event)}
                   
                    {event.speakers && (
                      <>
                        <br />
                        <span className="text-dark h6">{event.oradoresButton ?? 'Oradores:'}</span>
                        <span>{` ${event.speakers}`}</span>
                      </>
                    )}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    {event.url && (
                      <a
                        //style={{ width: event.preco ? '50%' : '100%' }}
                        href={event.url}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-soft-primary"
                      >
                        {event.buttonText ? event.buttonText : 'Reservar Lugar'}
                      </a>
                    )}
                    {event.preco && (
                      <h4 className='mt-2 mb-2'>
                        <span className="text-dark h6">{event.preco}</span>
                      </h4>
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}

    </Row>
  );
};

export const EventList = ({ categories, events }) => (
  <Row>
    <EventListItem categories={categories} events={events} />
  </Row>
);

export default EventList;
