import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Input, Label } from 'reactstrap';
import Newsletter from '../../pages/MakersUnit/components/Newsletter';
import FichaVisitaMUCPDF from '../../assets/pdf/FichaVisita_MakersUnit_2024.pdf';

//Import Icons
import FeatherIcon from 'feather-icons-react';
import discordSVG from '../../assets/icons/discord.svg';

// import images
import americanEx from '../../assets/images/payments/american-ex.png';
import discover from '../../assets/images/payments/discover.png';
import masterCard from '../../assets/images/payments/master-card.png';
import paypal from '../../assets/images/payments/paypal.png';
import visa from '../../assets/images/payments/visa.png';

//Import Images
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      horario: [
        { dia: '2ª e 3ª Feira', horario: 'Fechado' },
        { dia: '4ª Feira a Domingo', horario: '10:00-24:00' },
        // { dia: '2ª Feira', horario: 'Fechado' },
        // { dia: '3ª Feira', horario: 'Fechado' },
        // { dia: '4ª Feira', horario: '10:00-24:00' },
        // { dia: '5ª Feira', horario: '10:00-24:00' },
        // { dia: '6ª Feira', horario: '10:00-24:00' },
        // { dia: 'Sábado', horario: '10:00-24:00' },
        // { dia: 'Domingo', horario: '10:00-24:00' },
      ],
      paymentCardData: [
        {
          img: americanEx,
          title: 'American Express',
        },
        {
          img: discover,
          title: 'Discover',
        },
        {
          img: masterCard,
          title: 'Master Card',
        },
        {
          img: paypal,
          title: 'Paypal',
        },
        {
          img: visa,
          title: 'Visa',
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              <Col className="col-12">
                <div
                  className={
                    this.props.isBorderLine
                      ? 'footer-py-60 footer-border'
                      : 'footer-py-60'
                  }
                >
                  <Row>
                    <Col
                      lg={4}
                      md={4}
                      className="col-12 mb-0 mb-md-4 pb-0 pb-md-2"
                    >
                      <Link to="#" className="logo-footer">
                        <img
                          src={this.props.isLight ? logodark : logolight}
                          height="100"
                          alt=""
                        />
                      </Link>
                      <p
                        className={
                          this.props.isLight ? 'mt-4 text-muted' : 'mt-4'
                        }
                      >
                        Associação Makers Unit Club é um centro tecnológico que
                        faz parte da rede europeia de FabLabs. Oferecemos treino
                        técnico, promovemos a partilha de conhecimento e
                        desenvolvemos produtos.
                        <br />
                        <br />
                        NIF: 516925792
                        <br />
                        VAT: PT516925792
                      </p>
                      <a
                        href={FichaVisitaMUCPDF}
                        rel="noreferrer"
                        download="FichaVisita_MakersUnit_2024"
                        target="_blank"
                      >
                        Ficha de Visita
                      </a>
                    </Col>

                    <Col
                      lg={4}
                      md={4}
                      className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
                    >
                      <h5
                        className={
                          this.props.isLight
                            ? 'text-dark footer-head'
                            : 'text-light footer-head'
                        }
                      >
                        Morada
                      </h5>
                      <p
                        className={
                          this.props.isLight ? 'mt-4 text-muted' : 'mt-4'
                        }
                      >
                        Largo do Comércio 3<br />
                        2350-213 Parceiros de Igreja
                        <br />
                        Torres Novas, Portugal
                      </p>

                      <h5
                        className={
                          this.props.isLight
                            ? 'mt-4 text-dark footer-head'
                            : 'mt-4 text-light footer-head'
                        }
                      >
                        Horário de Abertura
                      </h5>
                      <ul className="list-unstyled footer-list mt-4">
                        {this.state.horario.map((dia, key) => (
                          <li key={key}>
                            <i className="uil uil-angle-right-b me-1"></i>{' '}
                            {dia.dia} - {dia.horario}
                          </li>
                        ))}
                      </ul>
                    </Col>

                    <Col
                      lg={4}
                      md={4}
                      className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
                    >
                      <h5
                        className={
                          this.props.isLight
                            ? 'text-dark footer-head'
                            : 'text-light footer-head'
                        }
                      >
                        Newsletter
                      </h5>
                      <p className="mt-4">
                        Inscreva-se para receber as nossas ofertas e novidades
                      </p>
                      <Newsletter />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="footer-py-30 footer-bar">
            <Container className="text-center">
              <Row className="align-items-center">
                <Col sm={8}>
                  <div className="text-sm-start">
                    <p className="mb-0">
                      © {new Date().getFullYear()} Desenvolvido por
                      <a href="mailto:engineering@makersunit.com">
                        {' '}
                        engineering@makersunit.com
                      </a>
                    </p>
                  </div>
                </Col>

                <Col sm={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <ul
                    className={
                      this.props.isLight
                        ? 'list-unstyled social-icon social mb-0 m t-4'
                        : 'list-unstyled social-icon foot-social-icon mb-0 mt-4'
                    }
                  >
                    <li className="list-inline-item me-1">
                      <a
                        href="https://facebook.com/makersunit.io"
                        rel="noreferrer"
                        target="_blank"
                        className="rounded"
                      >
                        <FeatherIcon
                          icon="facebook"
                          className="fea icon-sm fea-social"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item me-1">
                      <a
                        href="https://instagram.com/makersunit.io"
                        rel="noreferrer"
                        target="_blank"
                        className="rounded"
                      >
                        <FeatherIcon
                          icon="instagram"
                          className="fea icon-sm fea-social"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item me-1">
                      <a
                        href="https://twitter.com/makersunit"
                        rel="noreferrer"
                        target="_blank"
                        className="rounded"
                      >
                        <FeatherIcon
                          icon="twitter"
                          className="fea icon-sm fea-social"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item me-1">
                      <a
                        href="https://linkedin.com/company/makersunit"
                        rel="noreferrer"
                        target="_blank"
                        className="rounded"
                      >
                        <FeatherIcon
                          icon="linkedin"
                          className="fea icon-sm fea-social"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item me-1">
                      <a
                        href="https://discord.gg/VScspa5G"
                        rel="noreferrer"
                        target="_blank"
                        className="rounded"
                      >
                        <img src={discordSVG} alt="Discord" />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;

const MailchimpCustomForm = () => {
  return (
    <Form>
      <Row>
        <Col lg={12}>
          <div
            className={
              this.props.isLight
                ? 'foot-subscribe mb-3 foot-white'
                : 'foot-subscribe mb-3'
            }
          >
            <Label
              className={
                this.props.isLight ? 'form-label text-muted' : 'form-label'
              }
            >
              Escreva o seu email <span className="text-danger">*</span>
            </Label>
            <div className="form-icon position-relative">
              <FeatherIcon icon="mail" className="fea icon-sm icons" />
              <Input
                type="email"
                name="email"
                id="emailsubscribe"
                className={
                  this.props.isLight
                    ? 'form-control ps-5 rounded bg-light border'
                    : 'form-control ps-5 rounded'
                }
                placeholder="Email: "
                required
              />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="d-grid">
            <input
              type="submit"
              id="submitsubscribe"
              name="send"
              className={
                this.props.isLight ? 'btn btn-primary' : 'btn btn-soft-primary'
              }
              value="Subscrever"
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};
