import React, { useEffect, useState, Component } from 'react';

import { Container } from 'reactstrap';
import SectionTitle from '../../../../components/Shared/SectionTitle';
import Torneio, { useWindowSize, dummyMatches } from '../../components/Torneio';
import { Subscription } from '../../components/Query';
import { SUBSCRIBE_MAKERSUNIT_TORNEIOS } from '../../components/Query/queries';
import Carousel from '../../components/Carousel';

const TorneiosContainer = (props) => {
  const { event, matches } = props;
  const [width, height] = useWindowSize();
  const tituloEvento = `${event.game.title} (realizado a ${event.startsOn})`;

  return (
    <Container className="mt-100 mt-60">
      <SectionTitle title={event.title} desc={tituloEvento} />
      <Torneio.SingleElimination matches={matches} />
      {eventsPicture[event.id] && (
        <div style={{ marginTop: '20px' }}>
          <Carousel photos={eventsPicture[event.id]} />
        </div>
      )}
      {eventsStanding[event.id] && (
        <div style={{ marginTop: '20px' }}>
          <h6>Classificação</h6>
          <ul>
            {Object.keys(eventsStanding[event.id]).map((key, i) => {
              return (
                <li key={i}>
                  {eventsAscii[key]} {eventsStanding[event.id][key]}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </Container>
  );
};

const eventsStanding = {
  'ce6d87f4-388b-411a-ae1c-31594ccbcb17': {
    1: 'Luis & Fernando',
    2: 'Carlos & Miguel',
  },
  '115cdc3c-f261-4357-8719-68a636f32ed3': {
    1: 'Fernando',
    2: 'Miguel',
  },
};

const eventsAscii = {
  champion: '🏆',
  winner: '🏅',
  1: '🏆',
  2: '🏅',
};

const eventsPicture = {
  'ce6d87f4-388b-411a-ae1c-31594ccbcb17': [
    {
      img: 'https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/06975db9-a100-459c-b2a2-20990aab0170',
      text: 'Carlos & Miguel',
      subtext: 'Segundo Lugar - Pares Pool 1a Ed.',
    },
    {
      img: 'https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/336481f3-7eaf-47f7-87c4-99db34697718',
      text: 'Luis & Fernando',
      subtext: '🏆 Primeiro Lugar - Pares Pool 1a Ed.',
    },
  ],
};

export default class index extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.body.classList = '';
    // document.querySelector('.shoppingbtn').classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section style={{ marginTop: '120px' }} className="section">
          <Subscription query={SUBSCRIBE_MAKERSUNIT_TORNEIOS}>
            {(data) => {
              // console.log(data);
              return data.events.map((event, i) => {
                const matches = event.matches.map((match, j) => {
                  return {
                    id: match.id,
                    name: match.name,
                    nextMatchId: match.nextMatchId,
                    startTime: match.startTime,
                    state: match.staste,
                    tournamentRoundText: match.tournamentRoundText,
                    participants: match.participants.map((participant, y) => {
                      return {
                        id: participant.id,
                        isWinner: participant.isWinner,
                        name: participant.name.name, // NOTE: participant.name is ignored
                        resultText: participant.resultText,
                        status: null,
                      };
                    }),
                  };
                });
                // console.log(matches);

                // console.log(event.game.title);
                return (
                  <TorneiosContainer key={i} matches={matches} event={event} />
                );
              });
            }}
          </Subscription>
        </section>

        {/* <section className="section">
          <Container className="mt-100 mt-60">
            <SectionTitle
              title="Formação em Empresas"
              desc="É cada vez mais importante ter competências digitais e de pensamento computacional em qualquer profissão. Desenvolvemos programas de formação em empresas com o objetivo de catalizar a revolução digital e o pensamento intra-empreendedor dentro das empresas. Os cursos têm duração de 6 a 16 horas."
            />

            <BootCamps cursoTipo="Empresas" />
          </Container>
        </section> */}
      </React.Fragment>
    );
  }
}
