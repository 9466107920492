import { useQuery, useSubscription } from '@apollo/client';
import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import '../../../../assets/css/coffeelab-menu.css';
import {
  GET_MAKERSUNIT_MENU,
  GET_MAKERSUNIT_MENU_CATEGORIES,
  SUBSCRIBE_MAKERSUNIT_MENU,
  SUBSCRIBE_MAKERSUNIT_MENU_CATEGORIES,
} from '../../components/Query/queries';
import Jogos from '../../components/Jogos';
import axios from 'axios';

const CoffeeCategory = ({ items, category }) => {
  if (items.length == 0) return null;

  return (
    <div className="menu-coffee-">
      <h2 className="menu-coffee--group-heading">{category}</h2>
      <div className="menu-coffee--group">
        {items.map((item, key) => (
          <CoffeeItem key={key} item={item} />
        ))}
      </div>
    </div>
  );
};

const CoffeeCategorySimple = ({ items, category }) => {
  if (items.length == 0) return null;

  return (
    <div className="menu-coffee-">
      <h2 className="menu-coffee--group-heading">{category}</h2>
      <div className="menu-coffee--group">
        {items.map((item, key) => (
          <CoffeeItemSimple key={key} item={item} />
        ))}
      </div>
    </div>
  );
};

const CoffeeItemSimple = ({ item }) => {
  return (
    <div className="menu-coffee--item">
      <div className="menu-coffee--item-text">
        <h3 className="menu-coffee--item-heading">
          <span className="menu-coffee--item-name">{item.title}</span>
          <span className="menu-coffee--item-price">{item.price}€</span>
        </h3>
      </div>
    </div>
  );
};

const CoffeeItem = ({ item }) => {
  return (
    <div className="menu-coffee--item">
      <img
        src={
          item.image
            ? `https://sxlkgonvrfwktylbjxqh.storage.eu-central-1.nhost.run/v1/files/${item.image}`
            : 'https://dummyimage.com/600x400/ccc/fff'
        }
        alt="Black Placeholder Image"
        className="menu-coffee--item-img"
      />
      <div className="menu-coffee--item-text">
        <h3 className="menu-coffee--item-heading">
          <span className="menu-coffee--item-name">{item.title}</span>
          <span className="menu-coffee--item-price">{item.price}€</span>
        </h3>
        {item.description && (
          <p className="menu-coffee--item-desc">{item.description}</p>
        )}
      </div>
    </div>
  );
};

const CoffeLab = (props) => {
  const {
    data: dataMenuCategories,
    loading: loadingMenuCategories,
    error: errorMenuCategories,
  } = useSubscription(SUBSCRIBE_MAKERSUNIT_MENU_CATEGORIES);
  const {
    data: dataMenu,
    loading: loadingMenu,
    error: errorMenu,
  } = useSubscription(SUBSCRIBE_MAKERSUNIT_MENU);

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  useEffect(() => {
    // const fetcherData = async () => {
    //   try {
    //     const socket = new WebSocket(
    //       'wss://graphql-makersunit-edge-proxy.jtiagodev.workers.dev/ws',
    //     );

    //     // Wait for the socket to open
    //     await new Promise((resolve, reject) => {
    //       socket.addEventListener('open', resolve);
    //       socket.addEventListener('error', reject);
    //     });

    //     // Connection opened
    //     socket.send('Connection established');

    //     // Listen for messages
    //     socket.addEventListener('message', (event) => {
    //       console.log('Message from server ', event.data);
    //     });

    //     let count = 0;
    //     setInterval(() => {
    //       count++;
    //       socket.send(`Hi Server! ${count}`);
    //     }, 10000);
    //   } catch (error) {
    //     console.error('WebSocket error:', error);
    //   }
    // };
    // fetcherData();

    document.body.classList = '';
    // document.querySelector('.shoppingbtn').classList.add('btn-primary');
    window.addEventListener('scroll', scrollNavigation, true);

    return function cleanup() {
      window.removeEventListener('scroll', scrollNavigation, true);
    };
  }, []);

  if (loadingMenuCategories || loadingMenu) return <p>Loading...</p>;
  if (errorMenuCategories || errorMenu) return <p>Error!</p>;

  return (
    <>
      <section style={{ marginTop: '120px' }} className="section">
        <Container className="mt-100 mt-60">
          {dataMenuCategories.MakersUnit_menu_categories.map((category, key) =>
            category.includeImage ? (
              <CoffeeCategory
                key={key}
                items={dataMenu.MakersUnit_menu.filter(
                  (item) => item.category === category.id,
                )}
                category={category.title}
              />
            ) : (
              <CoffeeCategorySimple
                key={key}
                items={dataMenu.MakersUnit_menu.filter(
                  (item) => item.category === category.id,
                )}
                category={category.title}
              />
            ),
          )}
        </Container>
      </section>
      <section className="section">
        <Container className="mt-100 mt-60">
          <Jogos />
        </Container>
      </section>
    </>
  );
};

export default CoffeLab;
