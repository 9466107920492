import FeatherIcon from 'feather-icons-react';
import { Formik } from 'formik';
import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import * as yup from 'yup';

const Newsletter = (props) => {
  const [success, setSuccess] = React.useState(false);
  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Endereço inválido')
      .required('Campo Obrigatório'),
  });

  const submitHandler = (values, actions) => {
    const { email } = values;
    const payload = {
      updateEnabled: false,
      email,
      listIds: [7],
    };

    fetch(
      'https://makersunit-brevo-subscribe.jtiagodev.workers.dev/api/subscribe',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          setSuccess(true);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={submitHandler}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <div
                className={
                  props.isLight
                    ? 'foot-subscribe mb-3 foot-white'
                    : 'foot-subscribe mb-3'
                }
              >
                <div className="form-icon position-relative">
                  <FeatherIcon icon="mail" className="fea icon-sm icons" />
                  <Input
                    value={values.email}
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="emailsubscribe"
                    className={
                      props.isLight
                        ? 'form-control ps-5 rounded bg-light border'
                        : 'form-control ps-5 rounded'
                    }
                    placeholder="O seu email"
                    required
                  />
                  {success && <div id="feedback">Subcrito(a) com sucesso</div>}
                  {errors.email && <div id="feedback">{errors.email}</div>}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="d-grid">
                <input
                  type="submit"
                  id="submitsubscribe"
                  name="send"
                  className={
                    props.isLight ? 'btn btn-primary' : 'btn btn-soft-primary'
                  }
                  value="SUBSCREVER"
                />
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default Newsletter;
