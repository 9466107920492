import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Equipment from '../../components/Equipment';
import Portfolio from '../../components/Portfolio';
import Precario from '../../components/Precario';
import WallPrinter from "../../components/WallPrinter";
import ServicosFablab from "../../components/ServicosFablab";

export default class index extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.body.classList = '';
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };
  render() {
    return (
      <React.Fragment>
                <section style={{ marginTop: '120px' }} className="section">
          <Container className="mt-100 mt-60">
            <Equipment />
          </Container>
        </section>
        
        <section className="section">
          <Container className="mt-100 mt-60">
            <Precario />
          </Container>
        </section>

        <section className="section">
          <Container className="mt-100 mt-60">
            <Portfolio />
          </Container>
        </section>

        
        <section
             style={{ backgroundColor: 'lightgray' }}
               className="section">
          <Container className="mt-100 mt-60">
            <WallPrinter />
          </Container>
        </section>

        <section className="section">
          <Container className="mt-100 mt-60">
            <ServicosFablab />
          </Container>
        </section>

{/* 
        <section className="section">
          <Container className="mt-100 mt-60">
            <Modelacao />
          </Container>
        </section> */}

      </React.Fragment>
    );
  }
}
